import React, { useState, useEffect, useContext } from "react";
import { Button, Layout, Table, Space, Card, Input } from "antd";
import axios from "axios";
import moment from "moment";
import { EditFilled } from "@ant-design/icons";
import Registration from "../components/RegistrationModal";
import { useNavigate } from "react-router-dom";
import "./dashboard.css";
import Header from "../components/Header";
import memberContext from "../context/members/memberContext";

const { Content } = Layout;

const Dashboard = () => {
  const memberStore = useContext(memberContext);

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [member_id, set_member_id] = useState(null);
  const [search_val, set_search_val] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    let data = localStorage.getItem("idea-member-x-data");

    if (data) {
      data = JSON.parse(data);
      if (!open) {
        const config = {
          method: "get",
          url: `${axios.defaults.baseURL}/members`,
          headers: {
            Authorization: data.token,
          },
        };

        axios(config)
          .then(async function (response) {
            setData(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
        set_member_id(null);
      }
    } else {
      navigate("/login");
    }
  }, [open, navigate]);
  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      sortable: true,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Mobile No",
      dataIndex: "mobileno",
      key: "mobileno",
    },
    {
      title: "Alternate Mobile No",
      dataIndex: "alternate_mobileno",
      key: "alternate_mobileno",
      render: (text) => (text ? text : ""),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Source",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "Membership Card Number",
      dataIndex: "membership_card_no",
      key: "membership_card_no",
    },
    {
      title: "Expiry Date",
      dataIndex: "membership_expiry_date",
      key: "membership_expiry_date",
      render: (text) => moment(text).format("DD MMM YYYY"),
    },
    {
      title: "Issue Date",
      dataIndex: "membership_issue_date",
      key: "membership_issue_date",
      render: (text) => moment(text).format("DD MMM YYYY"),
    },
    {
      title: "Family Members",
      dataIndex: "family_members_count",
      key: "family_members_count",
    },
    {
      title: "Paid Membership",
      dataIndex: "is_paid_membership",
      key: "is_paid_membership",
      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: "Action",
      dataIndex: "mobileno",
      key: "mobileno",
      render: (member_id) => {
        return (
          <Button
            onClick={() => {
              set_member_id(member_id);
              setOpen(true);
              memberStore.update(
                {
                  ...memberStore.state,
                },
                true,
                member_id
              );
            }}
          >
            <EditFilled />
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Header />
      <Layout>
        <Layout className="site-layout">
          <Card
            title={
              <Button
                type="primary"
                onClick={() => {
                  setOpen(true);
                  memberStore.update(
                    {
                      ...memberStore.state,
                    },
                    true
                  );
                }}
              >
                + Add Member
              </Button>
            }
            extra={
              <Space>
                <Input
                  onChange={(e) => {
                    const currValue = e.target.value;
                    set_search_val(currValue);
                  }}
                  value={search_val}
                  placeholder="Search..."
                />
              </Space>
            }
          >
            <Registration open={open} setOpen={setOpen} member_id={member_id} />
          </Card>

          <Content className="mx-4">
            <Table
              bordered
              style={{
                borderRadius: "10px",
                border: "1px solid #eee",
                boxShadow: "10px 10px 107px -11px #eee",
              }}
              columns={columns}
              dataSource={
                data &&
                data.length &&
                data.map((x) => {
                  x["key"] = x.mobileno;
                  return x;
                })
              }
              scroll={{ x: 2000, y: "100vh" }}
            />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
export default Dashboard;
