import React from "react";
import { Button, Form, Input, Image, message } from "antd";
import styles from "./login.module.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Login = () => {
  const navigate = useNavigate();

  const onFinish = (values) => {
    const config = {
      method: "post",
      url: `${axios.defaults.baseURL}/login`,
      headers: {},
      data: values,
    };

    axios(config)
      .then(async function (response) {
        console.log(`response :: `, response);
        if (response && response.data && response.data.is_admin) {
          localStorage.setItem(
            "idea-member-x-data",
            JSON.stringify(response.data)
          );
          navigate("/");
        }
      })
      .catch(function (error) {
        console.log(error);
        message.error(`${error.response.data.message}`);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className={styles.container}>
      <div className="text-center">
        <Image
          src={require("../assets/images/print-logo.png")}
          height={200}
          width={300}
          preview={false}
        />
      </div>

      <Form
        className="py-5"
        name="login_form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        size="large"
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input placeholder="Username" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className={styles.btnSingIn}>
            Log In
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default Login;
