import React from "react";
import { Col, Row, Image, Modal } from "antd";
import { useNavigate } from "react-router-dom";
const { confirm } = Modal;

export default function Header() {
  const navigate = useNavigate();

  const showConfirm = () => {
    confirm({
      title: "Do you Want to Logout?",
      //   icon: <ExclamationCircleFilled />,
      onOk() {
        localStorage.removeItem("userSessionData");
        navigate("/login");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  return (
    <div className="m-4">
      <Row>
        <Col span={4}>
          <Image
            src={require("../assets/images/print-logo.png")}
            height={50}
            width={80}
            preview={false}
          />
        </Col>
        <Col span={4} offset={16}>
          <div className="text-right text-2xl">
            <span
              onClick={showConfirm}
              className="d-inline-block d-lg-none icon-width cursor-pointer"
            >
              <i className="fa fa-sign-out" />
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
}
