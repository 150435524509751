import { useState } from "react";
import MemberContext from "./memberContext";

const MemberState = (props) => {
  const initialState = {
    mobileno: "",
    is_paid_membership: true,
  };

  const [state, setState] = useState(initialState);

  const update = (state, setToinitialState = false, mobileno = "") => {
    if (setToinitialState) setState({ ...initialState, mobileno });
    else setState(state);
  };

  return (
    <MemberContext.Provider value={{ state, update }}>
      {props.children}
    </MemberContext.Provider>
  );
};

export default MemberState;
