import { useState } from "react";
import MemberContext from "./memberContext";

const LoginState = (props) => {
  const initialState = {
    isVerify: false,
    data: null,
  };

  const [state, setState] = useState(initialState);

  const update = (state) => {
    setState(state);
  };

  return (
    <MemberContext.Provider value={{ state, update }}>
      {props.children}
    </MemberContext.Provider>
  );
};

export default LoginState;
