import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import MemberState from "./context/members/MemberState";
import LoginState from "./context/members/LoginState";

function App() {
  return (
    <LoginState>
      <MemberState>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </MemberState>
    </LoginState>
  );
}

export default App;
