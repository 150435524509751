import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Switch,
  Modal,
  message,
  InputNumber,
} from "antd";
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import memberContext from "../context/members/memberContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const Registration = (props) => {
  const [form] = Form.useForm();
  let initialValues = {
    is_paid_membership: true,
    first_name: "",
    last_name: "",
    city: "",
    mobileno: "",
    alternate_mobileno: "",
    email: "",
    source: "",
    payment_ref: "",
    membership_issue_date: dayjs().add(1, "day"),
    membership_expiry_date: dayjs().add(1, "day").add(1, "year"),
  };
  let access_data = JSON.parse(localStorage.getItem("idea-member-x-data"));
  const [loading, setLoading] = useState(false);
  const { open, setOpen, mobileno } = props;

  const memberStore = useContext(memberContext);

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70,
        }}
        defaultValue={{
          value: "91",
          label: "91",
        }}
        disabled
      />
    </Form.Item>
  );

  const onFinish = (values) => {
    setLoading(true);
    values.membership_expiry_date =
      values.membership_expiry_date.format("YYYY-MM-DD");

    values.membership_issue_date =
      values.membership_issue_date.format("YYYY-MM-DD");

    const data = JSON.stringify({ ...values });

    const config = {
      method: "post",
      url: `${axios.defaults.baseURL}/member`,
      headers: {
        "Content-Type": "application/json",
        Authorization: access_data.token,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        setOpen(false);
      })
      .catch(function (error) {
        setLoading(false);
        message.error(`${error.response.data.message}`);
      });
  };
  const onFinishFailed = (errorInfo) => {
    setLoading(false);
    console.log("Failed:", errorInfo);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };

  const handleExpiryData = (date, dateString) => {
    const issue_date = dayjs(dateString);
    const expiry_date = issue_date.add(1, "year");
    form.setFieldValue("membership_expiry_date", expiry_date);
  };

  return (
    <>
      <Modal
        title={"Register New Member"}
        centered
        open={open}
        onCancel={() => setOpen(false)}
        width={700}
        destroyOnClose
        footer={[
          <Button key="cancel" onClick={() => setOpen(false)}>
            Cancel
          </Button>,

          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            form="registration_form"
            onClick={onFinish}
            loading={loading}
          >
            Submit
          </Button>,
        ]}
      >
        <div className="py-5 px-12">
          <Form
            form={form}
            name="registration_form"
            layout="vertical"
            initialValues={{
              size: "default",
              ...initialValues,
            }}
            id="registration_form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            size="default"
            autoComplete="off"
          >
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Please input your first name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[
                {
                  required: true,
                  message: "Please input your last name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="city"
              label="City"
              rules={[
                {
                  required: true,
                  message: "Please select City!",
                },
              ]}
            >
              <Select getPopupContainer={(trigger) => trigger.parentNode}>
                <Select.Option value="Hyderabad">Hyderabad</Select.Option>
                <Select.Option value="Mumbai">Mumbai</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="mobileno"
              label="Mobile Number"
              rules={[
                {
                  required: true,
                  message: "Please input your mobile number!",
                },
              ]}
            >
              <Input addonBefore={prefixSelector} />
            </Form.Item>
            <Form.Item
              name="alternate_mobileno"
              label="Alternative Mobile Number"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input addonBefore={prefixSelector} />
            </Form.Item>
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="source"
              label="Source"
              rules={[
                {
                  required: true,
                  message: "Please select source!",
                },
              ]}
            >
              <Select getPopupContainer={(trigger) => trigger.parentNode}>
                <Select.Option value="Clinic">Clinic</Select.Option>
                <Select.Option value="Corporate Partner">
                  Corporate Partner
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="membership_issue_date"
              label="Issue Date"
              rules={[
                {
                  required: true,
                  message: "Please select issue date!",
                },
              ]}
            >
              <DatePicker
                placement="bottomLeft"
                getPopupContainer={(trigger) => trigger.parentNode}
                disabledDate={disabledDate}
                onChange={handleExpiryData}
              />
            </Form.Item>
            <Form.Item
              name="membership_expiry_date"
              label="Expiry Date"
              rules={[
                {
                  required: true,
                  message: "Please select expiry date!",
                },
              ]}
            >
              <DatePicker
                placement="bottomLeft"
                getPopupContainer={(trigger) => trigger.parentNode}
                disabled
              />
            </Form.Item>
            <Form.Item
              label="How many people live in your immediate family?"
              name="family_members_count"
              rules={[
                {
                  required: true,
                  message: "Please input family member",
                },
              ]}
            >
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item
              label="Paid Membership"
              valuePropName="checked"
              name="is_paid_membership"
            >
              <Switch
                onChange={(e) => {
                  memberStore.update({
                    ...memberStore.state,
                    is_paid_membership: e,
                  });
                }}
              />
            </Form.Item>
            {memberStore.state.is_paid_membership && (
              <Form.Item
                name="payment_ref"
                label="Payment Ref."
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default Registration;
